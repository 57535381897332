import './PuxShare.scss'

import React, { FunctionComponent } from 'react'
import PuxIconBuilder from 'Shared/components/PuxIconBuilder/PuxIconBuilder'
import { t } from 'ttag'

const rootClass = `pux-share`
interface PuxShareType {
  sites: string[]
  path: string
  showTitle: boolean
}

const PuxShare: FunctionComponent<PuxShareType> = (props) => {
  const url = `${process.env.GATSBY_WEBSITE_URL}/${props.path}`
  const encodedUrl = encodeURIComponent(url)

  return (
    <div className={`${rootClass}`}>
      {props.showTitle && (
        <strong className={`${rootClass}-title`}>
          {t`EasySoftware.news.shareLabel`}:
        </strong>
      )}
      {props.sites.map((site, idx) => (
        <a
          className={`${rootClass}-item`}
          href={`https://www.addtoany.com/add_to/${site.toLowerCase()}?linkurl=${encodedUrl}&amp;linkname=`}
          target='_blank'
          key={idx}
        >
          <PuxIconBuilder icon={site + `Icon`} />
        </a>
      ))}
    </div>
  )
}

export default PuxShare
