import axios from 'axios'

const getTokenData = async () => {
  return await axios
    .get(`${process.env.GATSBY_ORCHARD_API_URL}/puxapi/preview/token`, {
      withCredentials: true,
    })
    .then((response) => response.data.token)
    .catch((error) => error)
}

export default getTokenData
