import axios from 'axios'
import React, { FunctionComponent } from 'react'
import getTokenData from 'Shared/utils/getGraphqlValidationToken'

import Loader from '../../components/core/Loader'
import KnowledgeBaseArticle, {
  KnowledgeBaseArticleType,
  PageType,
} from './KnowledgeBaseArticle'

const PreviewKnowledgeBaseArticle: FunctionComponent<KnowledgeBaseArticleType> = (
  props
) => {
  const pageData = props.data.orchard.easySoftwareKnowledgeBaseArticle[0]
  const [
    completeData,
    setCompleteData,
  ] = React.useState<KnowledgeBaseArticleType>()

  const query = `
  {
    easySoftwareKnowledgeBaseArticle(where: {contentItemId: "${pageData.contentItemId}", contentItemVersionId: "${pageData.contentItemVersionId}"} status: DRAFT) {
      displayText
      contentItemId
      contentItemVersionId
      knowledgeBaseArticleContent {
        html
      }
      knowledgeBaseArticleTags {
        termContentItems {
          displayText
        }
      }
      localization {
        localizations {
          ... on EasySoftwareKnowledgeBaseArticle {
            path
            published
            localization {
              culture
            }
          }
        }
      }
    }
  }`

  React.useEffect(async () => {
    const tokenData = await getTokenData()
    return await axios
      .post(
        `${process.env.GATSBY_API_URL}`,
        `query ${query}`.replace(/\n/g, ``),
        {
          headers: {
            'Content-Type': `application/graphql`,
            'Access-Control-Allow-Origin': `*`,
            Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          const documentData: PageType =
            response.data.data.easySoftwareKnowledgeBaseArticle[0]
          const data = { ...props }
          data.data.orchard.easySoftwareKnowledgeBaseArticle = [documentData]
          setCompleteData(data)
        }
      })
      .catch((error) => {
        console.log(`Preview Data Fetch Error:`, error)
      })
  }, [])

  return completeData ? (
    <KnowledgeBaseArticle {...completeData} />
  ) : (
    <Loader message='Loading Knowledge Base Article Draft Data' />
  )
}

export default PreviewKnowledgeBaseArticle
