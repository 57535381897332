const CHARACTERS_PER_SECOND = 21

const getReadingDuration = (input: string[]): string => {
  let chars = 0
  input.map((text) => {
    chars += typeof text === `string` ? text.length : 0
  })
  const minutes = chars / CHARACTERS_PER_SECOND / 60
  return `${Math.ceil(minutes)}`
}

export default getReadingDuration
