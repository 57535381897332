import axios from 'axios'
import React, { FunctionComponent } from 'react'
import getTokenData from 'Root/Shared/utils/getGraphqlValidationToken'

import Loader from '../../components/core/Loader'
import CategoryPage, { CategoryPageType, CategoryType } from './CategoryPage'

const PreviewCategoryPage: FunctionComponent<CategoryPageType> = (props) => {
  const pageData = props.data.orchard.easySoftwareCategoryPage[0]
  const [completeData, setCompleteData] = React.useState<CategoryPageType>()

  const query = `
  {
    easySoftwareCategoryPage(where: {contentItemId: "${pageData.contentItemId}", contentItemVersionId: "${pageData.contentItemVersionId}"} status: DRAFT) {
      contentType
      path
      contentItemId
      displayText
      categoryPageDescription {
        html
      }
    }
  }`

  React.useEffect(async () => {
    const tokenData = await getTokenData()
    return await axios
      .post(
        `${process.env.GATSBY_API_URL}`,
        `query ${query}`.replace(/\n/g, ``),
        {
          headers: {
            'Content-Type': `application/graphql`,
            'Access-Control-Allow-Origin': `*`,
            Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          const documentData: CategoryType =
            response.data.data.easySoftwareCategoryPage[0]
          const data = { ...props }
          data.data.orchard.easySoftwareCategoryPage = [documentData]
          setCompleteData(data)
        }
      })
      .catch((error) => {
        console.log(`Preview Data Fetch Error:`, error)
      })
  }, [])

  return completeData ? (
    <CategoryPage {...completeData} />
  ) : (
    <Loader message='Loading Category Page Draft Data' />
  )
}

export default PreviewCategoryPage
