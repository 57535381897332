import axios from "axios";
import React, { FunctionComponent } from "react";
import getTokenData from "Shared/utils/getGraphqlValidationToken";

import Loader from "../../components/core/Loader";
import FaqDetail, { FaqType, FunctionDetailType } from "./FaqDetail";

const PreviewFaqDetail: FunctionComponent<FunctionDetailType> = (props) => {
  const pageData = props.data.orchard.easySoftwareFaq[0];
  const [completeData, setCompleteData] = React.useState<FunctionDetailType>();

  const query = `
  {
    easySoftwareFaq(where: {contentItemId: "${pageData.contentItemId}", contentItemVersionId: "${pageData.contentItemVersionId}"} status: DRAFT) {
      contentType
      path
      contentItemId
      displayText
      faqAnswer {
        html
      }
    }
  }`;

  React.useEffect(async () => {
    const tokenData = await getTokenData();
    return await axios
      .post(
        `${process.env.GATSBY_API_URL}`,
        `query ${query}`.replace(/\n/g, ``),
        {
          headers: {
            "Content-Type": `application/graphql`,
            "Access-Control-Allow-Origin": `*`,
            Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          const documentData: FaqType = response.data.data.easySoftwareFaq[0];
          const data = { ...props };
          data.data.orchard.easySoftwareFaq = [documentData];
          setCompleteData(data);
        }
      })
      .catch((error) => {
        console.log(`Preview Data Fetch Error:`, error);
      });
  }, []);

  return completeData ? (
    <FaqDetail {...completeData} />
  ) : (
    <Loader message="Loading Case Study Draft Data" />
  );
};

export default PreviewFaqDetail;
