import './KnowledgeBaseArticle.scss'

import { graphql } from 'gatsby'
import React, { FunctionComponent } from 'react'
import WidgetBuilder from 'Shared/components/builders/WidgetBuilder'
import EasySoftwareBreadcrumbs, {
  BreadcrumbItem,
} from 'Shared/components/EasySoftwareBreadcrumbs/EasySoftwareBreadcrumbs'
import EasySoftwareSideCardNavigation from 'Shared/components/EasySoftwareSideCard/EasySoftwareSideCardNavigation'
import PuxColumns, {
  PuxMainColumn,
  PuxSideColumn,
} from 'Shared/components/PuxColumns/PuxColumns'
import { localizedPathType } from 'Shared/components/PuxLanguageSelector/PuxLanguageSelector'
import PuxShare from 'Shared/components/PuxShare/PuxShare'
import PuxWysiwyg, {
  PuxWysiwygHtmlBodyType,
} from 'Shared/components/PuxWysiwyg/PuxWysiwyg'
import { KnowledgeBaseMenuItemType } from 'Shared/utils/getKnowledgeBaseData'
import PuxMetaTags, { PuxMetaTagsType } from 'Shared/utils/PuxMetaTags'

import Layout from '../../components/core/Layout'

export const query = graphql`
  query($pagePath: String = "") {
    orchard {
      easySoftwareKnowledgeBaseArticle(first: 1, where: { path: $pagePath }) {
        displayText
        contentItemId
        contentItemVersionId
        knowledgeBaseArticleContent {
          html
        }
        knowledgeBaseArticleTags {
          termContentItems {
            displayText
          }
        }
        localization {
          localizations {
            ... on Orchard_EasySoftwareKnowledgeBaseArticle {
              path
              published
              localization {
                culture
              }
            }
          }
        }
      }
    }
  }
`

export interface PageType {
  displayText: string
  contentItemId: string
  contentItemVersionId: string
  knowledgeBaseArticleContent: PuxWysiwygHtmlBodyType
  knowledgeBaseArticleTags: {
    termContentItems: [
      {
        displayText: string
      }
    ]
  }
  localization: {
    localizations: localizedPathType[]
  }
}

export interface KnowledgeBaseArticleType {
  data: {
    orchard: {
      easySoftwareKnowledgeBaseArticle: [PageType]
    }
  }
  pageContext: {
    breadcrumbs: [BreadcrumbItem]
    metaTags: PuxMetaTagsType
    knowledgeBaseData: KnowledgeBaseMenuItemType[]
    sideCardWidgets: any
    specificSideCardWidgets: any
    path: string
  }
}

const KnowledgeBaseArticle: FunctionComponent<KnowledgeBaseArticleType> = ({
  data,
  pageContext,
}) => {
  const breadcrumbsData = pageContext.breadcrumbs
  const knowledgeBaseArticleData =
    data.orchard.easySoftwareKnowledgeBaseArticle[0]

  return (
    <Layout
      localizedPath={knowledgeBaseArticleData.localization?.localizations}
    >
      <EasySoftwareBreadcrumbs items={breadcrumbsData} />
      <PuxMetaTags {...pageContext.metaTags} />
      <div className='pux-container knowledge-base-article'>
        <PuxColumns>
          <PuxMainColumn>
            <h1>{knowledgeBaseArticleData.displayText}</h1>
            <div className='knowledge-base-article-info'>
              <div className='knowledge-base-article-info-tags'>
                {knowledgeBaseArticleData.knowledgeBaseArticleTags?.termContentItems?.map(
                  (item) => (
                    <div className='knowledge-base-article-info-tag'>
                      {item.displayText}
                    </div>
                  )
                )}
              </div>
              <PuxShare
                sites={[`Facebook`, `Twitter`, `Linkedin`, `Pinterest`]}
                path={pageContext.path}
                showTitle={true}
              />
            </div>
            <PuxWysiwyg
              content={knowledgeBaseArticleData.knowledgeBaseArticleContent}
            />
          </PuxMainColumn>
          <PuxSideColumn>
            <div className='sidebar-sticky'>
              <EasySoftwareSideCardNavigation
                type='knowledgeBase'
                sideCardNavigationLinks={pageContext.knowledgeBaseData}
              />
              <WidgetBuilder
                widgetBuilderData={
                  pageContext.specificSideCardWidgets ||
                  pageContext.sideCardWidgets
                }
              />
            </div>
          </PuxSideColumn>
        </PuxColumns>
      </div>
    </Layout>
  )
}

export default KnowledgeBaseArticle
