import './PlansTable.scss'

import loadable from '@loadable/component'
import React, { FunctionComponent } from 'react'
import PuxButton, {
  PuxButtonColor,
  PuxButtonStyle,
} from 'Shared/components/PuxButton/PuxButton'
import PuxIconBuilder from 'Shared/components/PuxIconBuilder/PuxIconBuilder'
import PuxWysiwyg, {
  PuxWysiwygHtmlBodyType,
} from 'Shared/components/PuxWysiwyg/PuxWysiwyg'
import { getIsoCodesWithPrefixPublic } from 'Shared/utils/localeURL'
import { t } from 'ttag'

import { PricingCurrencyType, PricingFeatureType } from './PricingPage'

const Tooltip = loadable(() => import(`@material-ui/core/Tooltip`))

export interface ContentPickerType {
  internal: boolean[]
  text: string[]
  url: string[]
}

interface PlansTableType {
  currencies: [PricingCurrencyType]
  features: [PricingFeatureType]
  enterpriseContent: PuxWysiwygHtmlBodyType
  buyLink: ContentPickerType
  locale: string
}

const rootClass = `pricing-table`
const PRICING_PLANS = [`Essential`, `Business`, `Platform`]
const IsoCode =
  getIsoCodesWithPrefixPublic().filter(
    (iso) => iso.locale === process.env.LOCALE
  )[0].isoCode ?? `en-GB`

const PlansTable: FunctionComponent<PlansTableType> = (props) => {
  const [currencyIdx, setCurrencyIdx] = React.useState(0)

  const getPrice = (currency: PricingCurrencyType, plan: string) => {
    const price = (currency as any)[`pricingCurrencyPrice${plan}`]

    const number = new Intl.NumberFormat(IsoCode, {
      style: `currency`,
      currency: currency.displayText.toUpperCase(),
    }).formatToParts(price)

    const numberInteger: string[] = []
    const numberFraction: string[] = []
    const numberCurrency: string[] = []
    let numberCurrencyLast = true

    number.map((part, idx) => {
      const type = part.type
      if (type === `integer` || type === `group`) {
        numberInteger.push(part.value)
      }

      if (type === `fraction`) {
        if (part.value !== `00`) numberFraction.push(part.value)
      }

      if (type === `literal` || type === `currency`) {
        if (type === `currency` && idx === 0) {
          numberCurrencyLast = false
        }
        numberCurrency.push(part.value.replace(`US`, ``))
      }
    })

    return (
      <span className={`${rootClass}-price-value`}>
        {numberCurrencyLast ? `` : numberCurrency}
        {numberInteger.length ? numberInteger.join(``) : ``}
        <sup>{numberFraction.length ? numberFraction.join(``) : ``}</sup>
        {numberCurrencyLast ? numberCurrency : ``}
      </span>
    )
  }

  const getCell = (item: PricingFeatureType, domain: string) => {
    const available: boolean = (item as any)[`pricingFeaturePlan${domain}`]
    return (
      <div
        className={`${rootClass}-cell${available ? `` : ` empty`}`}
        key={item.contentItemId}
      >
        {available ? (
          <>
            <PuxIconBuilder
              icon='CheckIcon'
              options={{
                color: {
                  colorPrimary: `#00E099`,
                  colorSecondary: `#ffffff`,
                },
                hoverColor: {
                  colorPrimary: `#00E099`,
                  colorSecondary: `#ffffff`,
                },
              }}
            />
            <span className={`${rootClass}-cell-label`}>
              {item.displayText}
            </span>
          </>
        ) : undefined}
      </div>
    )
  }

  const getCurrencySelector = (currencyIdx: number) => {
    return (
      <select
        className='pux-select'
        onChange={(event) => setCurrencyIdx(Number(event.target.value))}
      >
        {props.currencies.map((item, idx) => (
          <option
            key={item.displayText}
            value={idx}
            selected={currencyIdx === idx}
          >
            {item.displayText} ({item.pricingCurrencySymbol})
          </option>
        ))}
      </select>
    )
  }

  return (
    <div className={`${rootClass}`}>
      <div className={`${rootClass}-currency-mobile`}>
        {getCurrencySelector(currencyIdx)}
      </div>
      <div className={`${rootClass}-columns`}>
        <div className={`${rootClass}-column ${rootClass}-column-label`}>
          <div className={`${rootClass}-sticker-placeholder`}>&nbsp;</div>
          <div className={`${rootClass}-price ${rootClass}-price-dummy`}>
            <h4>&nbsp;</h4>
            <span className={`${rootClass}-price-value`}>&nbsp;</span>
            &nbsp;
          </div>
          {props.features.map((item) => (
            <div key={item.contentItemId} className={`${rootClass}-cell`}>
              {item.displayText}
              <Tooltip title={item.pricingFeatureTooltip} placement='top'>
                <div>
                  <PuxIconBuilder icon='InfoIcon' />
                </div>
              </Tooltip>
            </div>
          ))}
        </div>

        {PRICING_PLANS.map((plan) => (
          <div
            className={`${rootClass}-column ${rootClass}-column-${plan.toLowerCase()}`}
            key={plan}
          >
            <div
              className={
                plan === `Business`
                  ? `${rootClass}-sticker`
                  : `${rootClass}-sticker-placeholder`
              }
            >
              {t`Redmine.Pricing.MostPopular`}
            </div>
            <div className={`${rootClass}-price`}>
              <h4>{plan === `Essential` ? plan + `s` : plan}</h4>
              {getPrice(props.currencies[currencyIdx], plan)}
              <div className={`${rootClass}-amount`}>
                {t`Redmine.Pricing.UserPerMonth`}
              </div>
            </div>
            {props.features.map((item) => getCell(item, plan))}
            <div className={`${rootClass}-cta`}>
              <PuxButton
                buttonType={
                  plan === `Business`
                    ? PuxButtonStyle.FILLED
                    : PuxButtonStyle.OUTLINED
                }
                buttonColor={PuxButtonColor.PRIMARY}
                buttonLink={{
                  url: [`#trial`],
                  internal: [false],
                  text: [t`Redmine.Pricing.TryIt`],
                }}
              />
              <PuxButton
                buttonType={PuxButtonStyle.LINK}
                buttonColor={PuxButtonColor.PRIMARY}
                buttonLink={{
                  url: [
                    `${props.buyLink.url[0]
                    }/?pricing.plan=${plan.toLowerCase()}&pricing.currency=${props.currencies[
                      currencyIdx
                    ].displayText.toUpperCase()}&pricing.lang=${props.locale}`,
                  ],
                  internal: [props.buyLink.internal[0]],
                  text: [props.buyLink.text[0]],
                }}
              />
            </div>
          </div>
        ))}

        <div className={`${rootClass}-column ${rootClass}-column-enterprise`}>
          <div className={`${rootClass}-sticker-placeholder`}>
            &nbsp;
            <div className={`${rootClass}-currency-desktop`}>
              {getCurrencySelector(currencyIdx)}
            </div>
          </div>
          <div className={`${rootClass}-price`}>
            <h4>{t`Redmine.Pricing.Enterprise`}</h4>
          </div>
          <div className={`${rootClass}-enterprise-content`}>
            <PuxWysiwyg content={props.enterpriseContent} />
          </div>
          <div className={`${rootClass}-cta`}>
            <PuxButton
              buttonType={PuxButtonStyle.OUTLINED}
              buttonColor={PuxButtonColor.PRIMARY}
              buttonLink={{
                url: [`/`],
                internal: [true],
                text: [t`Redmine.Pricing.LetsTalk`],
              }}
              buttonOpenModalId='talk'
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PlansTable
