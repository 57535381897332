import './ArticlePage.scss'

import { graphql, PageProps } from 'gatsby'
import React, { FunctionComponent } from 'react'
import PuxMetaTags, { PuxMetaTagsType } from 'Root/Shared/utils/PuxMetaTags'
import WidgetBuilder from 'Shared/components/builders/WidgetBuilder'
import EasySoftwareBreadcrumbs, {
  BreadcrumbItem,
} from 'Shared/components/EasySoftwareBreadcrumbs/EasySoftwareBreadcrumbs'
import PuxColumns, {
  PuxMainColumn,
  PuxSideColumn,
} from 'Shared/components/PuxColumns/PuxColumns'
import { localizedPathType } from 'Shared/components/PuxLanguageSelector/PuxLanguageSelector'
import PuxWysiwyg, {
  PuxWysiwygHtmlBodyType,
} from 'Shared/components/PuxWysiwyg/PuxWysiwyg'
import { WidgetPuxSectionType } from 'Shared/components/widgets/WidgetPuxSection'

import Layout from '../../components/core/Layout'

export interface ArticleType {
  contentType: string
  contentItemId: string
  contentItemVersionId: string
  path: string
  displayText: string
  articlePerex: PuxWysiwygHtmlBodyType
  articleContent: PuxWysiwygHtmlBodyType
  localization: {
    localizations: localizedPathType[]
  }
}

export interface ArticlePageType extends PageProps {
  data: {
    orchard: {
      easySoftwareArticle: [ArticleType]
    }
  }
  pageContext: {
    pagePath: string
    widgets: {
      articleWidgets: [WidgetPuxSectionType]
    }
    metaTags: PuxMetaTagsType
    breadcrumbs: [BreadcrumbItem]
    sideCardWidgets: any
  }
}

export const query = graphql`
  query($pagePath: String = "") {
    orchard {
      easySoftwareArticle(first: 1, where: { path: $pagePath }) {
        contentType
        contentItemId
        path
        displayText
        localization {
          localizations {
            ... on Orchard_EasySoftwareArticle {
              path
              published
              localization {
                culture
              }
            }
          }
        }
        articlePerex {
          html
        }
        articleContent {
          html
        }
      }
    }
  }
`

const rootClass = `article-page`

const ArticlePage: FunctionComponent<ArticlePageType> = (props) => {
  const data = props.data.orchard.easySoftwareArticle[0]
  const widgetsData = props.pageContext.widgets
  const sideCardWidgetData = props.pageContext.sideCardWidgets
  const breadCrumbsData = props.pageContext.breadcrumbs

  return (
    <Layout localizedPath={data.localization?.localizations}>
      <EasySoftwareBreadcrumbs items={breadCrumbsData} />
      <PuxMetaTags {...props.pageContext.metaTags} />
      <div className={rootClass}>
        <div className='pux-container'>
          <PuxColumns>
            <PuxMainColumn>
              {data.displayText.trim().length && (
                <h1 className={`${rootClass}-title`}>{data.displayText}</h1>
              )}

              <PuxWysiwyg
                className={`${rootClass}-perex perex`}
                content={data.articlePerex}
              />

              <PuxWysiwyg
                className={`${rootClass}-content`}
                content={data.articleContent}
              />
              <WidgetBuilder widgetBuilderData={widgetsData.articleWidgets} />
            </PuxMainColumn>
            <PuxSideColumn>
              <div className='sidebar-sticky'>
                <WidgetBuilder widgetBuilderData={sideCardWidgetData} />
              </div>
            </PuxSideColumn>
          </PuxColumns>
        </div>
      </div>
    </Layout>
  )
}

export default ArticlePage
